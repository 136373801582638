import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import shallow from 'zustand/shallow';
import { useLocation } from 'react-router-dom';
import { useUserDetails } from '../Stores/UserDetails';
import { storage } from '../Stores/AppStore';
import { ACCELERATE_PATH, UNAUTH_PATHS } from '../Constants/routes';
import { isRexelUser } from '../Utils/usersTypes';
import { POSTHOG_RELATED_ENVS } from '../Constants/Constant';

const unAuthenticatedPaths = [...UNAUTH_PATHS];

const useIdentifyUser = () => {
  const posthog = usePostHog();
  const isLoggedIn = useUserDetails(state => state.isLoggedIn, shallow);
  const { pathname } = useLocation();
  const isAcceleratePath = pathname.startsWith(ACCELERATE_PATH[0]) || pathname.startsWith(ACCELERATE_PATH[1]);
  const relativePathname = pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname;

  const rexelUser = isRexelUser();

  if (rexelUser) {
    unAuthenticatedPaths.push(...['/logout', '/login/attentive']);
  }

  const userId = storage.get('userId');
  const emailId = storage.get('emailId');
  useEffect(() => {
    const verifyPosthogUser = POSTHOG_RELATED_ENVS.includes(process.env.APP_ENV || '');
    if (userId && isLoggedIn && verifyPosthogUser) {
      // Identify user on posthog
      posthog.identify(userId, { email: emailId, distinct_id: userId });
    } else if (unAuthenticatedPaths.indexOf(relativePathname) === -1 && !isAcceleratePath) {
      // User is not logged in but is trying to access a route which requires authentication
      posthog.reset();
    }
  }, [posthog, userId, emailId, isLoggedIn]);
};

export default useIdentifyUser;
