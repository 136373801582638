import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { MAP_LAYERS, MEASUREMENT_TABLE_VIEW, TOOLS_ID } from '../Constants/Constant';

const REQUEST_INITIAL_STATE = {
  isCurrentVersionLatest: true,
  timelineComponentIndex: null,
  requestData: {},
  collapsed: { measurementPanel: false, filePanel: false, expandedView: false },
  currentPageId: null,
  requestJustCreated: false,
  map: { fullscreen: false },
  showImageries: true,
  toolbar: {
    active: null,
    modal: {},
    isAddingNote: false
  },
  layerVisibility: {
    [MAP_LAYERS.ARROW]: true,
    [MAP_LAYERS.ICON]: true,
    [MAP_LAYERS.NOTES]: true,
    [MAP_LAYERS.LABEL_BOXES]: true,
    [MAP_LAYERS.PARCEL]: true,
    [MAP_LAYERS.OLD_PARCEL]: true
  },
  vintageImagery: {}, // {imageries: {}, selected: <selected imagery id>},
  icons: { list: [], loading: false },
  clipboardFeatures: null,
  selectedFeatures: [],
  tagsSelectedFeatures: [],
  tagsSelectedLayers: [],
  editZoneActive: false,
  activeFeatureId: null,
  showZones: true,
  showTipModal: false,
  tagQuery: null,
  hiddenGeoms: {},
  measurementTableViewData: {
    view: MEASUREMENT_TABLE_VIEW.DEFAULT_VIEW,
    zoneIds: []
  },
  blinkedLyr: { id: null, geomType: null, isVisible: null, intervalId: null },
  expandedLayers: {},
  hiddenLayers: {},
  highlightedLayers: [],
  supplementaryData: [],
  notes: [],
  filters: {
    panel: {},
    hidden_features: {}
  },
  resubmitOptions: {},
  currentPageNo: null,
  curFileId: null,
  gpt_processing_status: null,
  is_chat_positive: null,
  fetchingRequest: false
};

const reducer = (state: $TSFixMe, { type, payload }: $TSFixMe) => {
  switch (type) {
    case 'SET_TAG_QUERY':
      return { ...state, tagQuery: payload };
    case 'SET_SHOW_TIP_MODAL':
      return { ...state, showTipModal: payload };
    case 'ACTIVE_FEATURE':
      return { ...state, activeFeatureId: payload };
    case 'ZONES_VISIBLE':
      return { ...state, showZones: payload };
    case 'EDIT_ZONE_ACTIVE':
      return {
        ...state,
        editZoneActive: payload
      };
    case 'SET_TAGS_SELECTED_FEATURES':
      return {
        ...state,
        tagsSelectedFeatures: payload ? [...payload] : []
      };

    case 'SET_TAGS_SELECTED_LAYERS':
      return { ...state, tagsSelectedLayers: payload };
    case 'SET_SELECTED_FEATURES':
      return {
        ...state,
        selectedFeatures: payload ? [...payload] : []
      };
    case 'SET_CLIPBOARD_FEATURES':
      return {
        ...state,
        clipboardFeatures: payload
      };
    case 'RESET_REQUEST_STATE':
      return { ...state, ...REQUEST_INITIAL_STATE };
    case 'SET_LAYER_VISIBILTY':
      return { ...state, layerVisibility: payload };
    case 'SET_ADDING_NEW_NOTE':
      return { ...state, toolbar: { ...state.toolbar, isAddingNote: payload } };
    case 'SET_VINTAGE_IMAGERIES':
      return { ...state, vintageImagery: payload };
    case 'SET_TOOL_ACTIVE':
      if ([TOOLS_ID.TAG_TOOL, TOOLS_ID.LASSO_TAG].includes(payload?.toolId)) {
        return {
          ...state,
          collapsed: { ...state.collapsed, filePanel: true },
          toolbar: { ...state.toolbar, active: payload }
        };
      } else {
        return { ...state, toolbar: { ...state.toolbar, active: payload } };
      }
    case 'SET_TOOL_MODAL':
      return { ...state, toolbar: { ...state.toolbar, modal: payload } };
    case 'SET_ICONS_LIST':
      return {
        ...state,
        icons: { ...state?.icons, ...payload }
      };
    case 'SHOW_IMAGERIES':
      return { ...state, showImageries: payload };
    case 'SET_MAP_FULLSCREEN':
      return {
        ...state,
        map: {
          ...state.map,
          fullscreen: payload
        }
      };
    case 'SET_COLLAPSED':
      return {
        ...state,
        collapsed: { ...state.collapsed, ...payload }
      };
    case 'SET_EDIT_USER_ACTIVE_TAB':
      return {
        ...state,
        requestData: {
          ...state.requestData,
          ...payload
        }
      };
    case 'SET_OUTPUT_MODE':
      return {
        ...state,
        requestData: {
          ...state.requestData,
          output_mode: payload
        }
      };
    case 'UPDATE_MEASUREMENT_VIEW':
      return {
        ...state,
        requestData: {
          ...state.requestData,
          output_mode: payload
        }
      };
    case 'SET_REQUEST':
      return {
        ...state,
        requestData: payload
      };

    case 'SET_CURRENT_PAGEID':
      return { ...state, currentPageId: payload };
    case 'SET_CURRENT_PAGE_NO':
      return { ...state, currentPageNo: payload };
    case 'SET_CURRENT_FILE_ID':
      return { ...state, curFileId: payload };

    case 'SET_TIMELINE_COMPONENT_INDEX':
      return {
        ...state,
        timelineComponentIndex: payload
      };
    case 'SET_REQUEST_JUST_CREATED':
      return {
        ...state,
        requestJustCreated: payload
      };
    case 'FETCHING_REQUEST':
      return {
        ...state,
        fetchingRequest: payload
      };
    case 'SET_REQUEST_NAME':
      return {
        ...state,
        requestData: {
          ...state.requestData,
          name: payload
        }
      };
    case 'SET_ACTIVE_SHEET_STATUS': {
      return {
        ...state,
        requestData: {
          ...state.requestData,
          is_active_sheet_disabled: payload
        }
      };
    }
    case 'SET_LATEST_VERSION_LIST':
      return {
        ...state,
        requestData: {
          ...state.requestData,
          latestVersionList: payload
        }
      };
    case 'SET_LATEST_VERSION':
      if (payload.pageId) {
        let latestVersionList = state.requestData.latestVersionList ? [...state.requestData.latestVersionList] : [];
        latestVersionList = latestVersionList.map(item => {
          if (item.pageId === payload.pageId) {
            return { ...item, isLatestVersion: payload.isLatestVersion };
          }
          return item;
        });
        return {
          ...state,
          requestData: {
            ...state.requestData,
            latestVersionList
          },
          isCurrentVersionLatest: payload.isLatestVersion
        };
      }
      return {
        ...state,
        isCurrentVersionLatest: payload.isLatestVersion
      };
    case 'SET_MEASUREMENT_VIEW':
      return {
        ...state,
        measurementTableViewData: payload
      };
    case 'SUPPLEMENTARY_FILES_DATA':
      return { ...state, supplementaryData: payload };
    case 'SET_BLINKED_LAYER':
      return { ...state, blinkedLyr: { ...state?.blinkedLyr, ...payload } };
    case 'SET_HIGHLIGHTED_LAYERS':
      return { ...state, highlightedLayers: [...payload] };
    case 'SET_NOTES':
      return {
        ...state,
        notes: [...payload]
      };

    case 'SET_EXPANDED_LAYERS':
      return {
        ...state,
        expandedLayers: { ...state.expandedLayers, [payload?.sheet_id]: payload.feature_id }
      };

    case 'SET_HIDDEN_LAYERS':
      return {
        ...state,
        hiddenLayers: { ...state.hiddenLayers, ...payload }
      };
    case 'SET_RESUBMIT_OPTIONS':
      return {
        ...state,
        resubmitOptions: payload.options,
        timelineComponentIndex: payload.timelineComponentIndex,
        requestData: payload.requestPayload
      };
    case 'SET_HIDDEN_GEOMS':
      return {
        ...state,
        hiddenGeoms: { ...state.hiddenGeoms, ...payload }
      };
    case 'CANCEL_RESUBMIT_REQUEST':
      return {
        ...state,
        showZones: payload.zonesVisibility,
        timelineComponentIndex: payload.timelineComponentIndex,
        requestData: payload.requestData
      };
    default:
      return state;
  }
};

const store = (set: $TSFixMe) => ({
  ...REQUEST_INITIAL_STATE,
  dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
});

export const useRequest = create(devtools(store));
