import React, { ReactElement } from 'react';
import { PostHog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { POSTHOG_RELATED_ENVS } from '../Constants/Constant';

const PosthogProvider = ({ children, client }: { children: ReactElement; client: PostHog }) => {
  const initializePosthog = POSTHOG_RELATED_ENVS.includes(process.env.APP_ENV || '');
  if (initializePosthog) {
    return <PostHogProvider client={client}>{children}</PostHogProvider>;
  }
  return children;
};

export default PosthogProvider;
